<template>
  <PDialog
    classes="order-dialog"
    :loading="isInitializing"
    :title="dialogTitle"
    @close="onCancel"
  >
    <div class="flex-1 overflow-y-hidden bg-gray-100 shadow-inner">
      <POrderModified
        :disabled="isUpdating"
        :orderId="order.id"
        :orderModified="order.modified"
        :orderModifiedBy="order.modifiedBy"
        :trigger="entityModifiedError"
        @modified="onModified"
        @dismiss="init(order.id)"
      />

      <div class="flex flex-col h-full overflow-y-auto md:flex-row md:overflow-y-hidden">
        <div class="p-4 border-r order-dialog-header md:overflow-y-auto">
          <template v-if="isSchedule">
            <PWeekdayPicker
              ref="firstScheduleInput"
              :disabled="isUpdating || isModified"
              :focus="isSchedule"
              :label="$tk('OrderDialog.ReceptionDays')"
              :value="weekdays"
              @input="setWeekdays"
            />

            <div class="flex my-4 space-x-4">
              <div class="w-1/2">
                <PNumber
                  v-model="order.createNumWeeks"
                  class="w-24"
                  :label="$tk('OrderDialog.IntervalWeeks')"
                  :max="52"
                  :min="1"
                />
              </div>
              <div class="w-1/2">
                <PInput
                  v-if="isSchedule"
                  v-model="order.reference"
                  :disabled="isUpdating || isModified"
                  :label="$tk('Common.Order.Reference')"
                />
              </div>
            </div>
            <p-toggle
              v-model="order.isActive"
              class="py-2"
              label="Aktiv bestilling"
            ></p-toggle>
          </template>

          <div class="flex space-x-4">
            <div class="w-1/2">
              <PDatePicker
                v-if="isOrder"
                v-model="order.dateSend"
                :allOpen="isGL"
                :disabled="isUpdating || isModified"
                :error="dateError"
                :focus="isTO || isHE"
                :label="dateLabel"
                :min="minDate"
                :required="true"
              />

              <PDatePicker
                v-if="isSchedule"
                v-model="order.dateBegins"
                :disabled="isUpdating || isModified"
                :label="dateLabel"
                :min="minDate"
                :required="true"
              />
            </div>

            <div
              v-if="isTO || isHE"
              class="w-1/2"
            >
              <PInput
                v-if="isOrder && isTO"
                v-model="order.receiversRef"
                label="t:OrderDialog.MyReference"
                :disabled="isUpdating || isModified"
              />

              <PInput
                v-if="isOrder && isHE"
                v-model="order.sendersRef"
                label="t:OrderDialog.MyReference"
                :disabled="isUpdating || isModified"
              />

              <PDatePicker
                v-if="isSchedule"
                v-model="order.dateEnds"
                :clearable="true"
                :disabled="isUpdating || isModified"
                :label="dateEndsLabel"
                :min="order.dateBegins"
                :rightAlign="true"
              />
            </div>
          </div>

          <template v-if="isOrder && isGL">
            <div class="mt-4">
              <PLocationPicker
                transactionType="GL"
                :value="order.locationIdTo"
                :corporate="true"
                :disabled="isUpdating || isModified"
                :excludeSr="true"
                :focus="true"
                :label="$tk('Common.Order.To')"
                :pooling="true"
                :required="true"
                @select="onLocationToSelect"
              />
            </div>

            <div class="flex mt-4 space-x-4">
              <div class="w-1/2">
                <PInput
                  v-model="order.receiversRef"
                  :disabled="isUpdating || isModified"
                  :label="$tk('Common.Order.ReceiversRef')"
                  :required="true"
                />
              </div>
              <div class="w-1/2">
                <PInput
                  v-model="order.sendersRef"
                  :disabled="isUpdating || isModified"
                  :label="$tk('Common.Order.SendersRef')"
                />
              </div>
            </div>
          </template>

          <template v-if="isGL">
            <PTextarea
              v-model="commentContent"
              class="mt-4"
              :disabled="isUpdating || isModified"
              :label="$tk('Common.Order.Comment')"
            />
          </template>

          <template v-if="isTO || isHE">
            <PSelect
              v-model="selectedAddressKey"
              class="mt-4"
              item-text="name"
              item-value="key"
              :disabled="isUpdating || isModified"
              :fixedHeight="false"
              :items="addresses"
              :label="isTO ? $tk('Common.Order.DeliveryAddress') : $tk('Common.Order.PickupAddress')"
              :required="true"
              :searchable="true"
            >
              <template v-slot:caption="{ item }">
                <PAddressItem :address="item" />
              </template>
              <template v-slot="{ item }">
                <PAddressItem :address="item" />
              </template>
            </PSelect>

            <div
              v-if="isOrder"
              class="flex mt-4 space-x-4"
            >
              <div class="w-1/2">
                <PInput
                  v-model="order.shipmentRef"
                  label="t:OrderDialog.TransportRef"
                  :disabled="isUpdating || isModified"
                />
              </div>
              <div class="w-1/2">
                <PInput
                  v-model="order.shipmentCarNumber"
                  label="t:OrderDialog.CarNo"
                  :disabled="isUpdating || isModified"
                />
              </div>
            </div>


            <PTextarea
              v-if="isOrder"
              v-model="order.loadText"
              class="mt-4"
              label="t:Common.Order.LoadText"
              :disabled="isUpdating || isModified"
            />

            <PTextarea
              v-model="order.transportText"
              class="mt-4"
              label="t:Common.Order.TransportText"
              :disabled="isUpdating || isModified"
            />

            <PCheckbox
              class="mt-4"
              :value="order.noTransport"
              :disabled="isLoadingProducts || isUpdating || isModified"
              :label="noTransportLabel"
              @input="onNoTransportToggle"
            />
            <div
              v-if="order.noTransport"
              class="mt-4"
            >
              <div class="mb-2">
                {{ $tk("OrderDialog.FuelAndCarType") }}
              </div>

              <PLoader v-if="!fuelTypes || !fuelTypes.length" />
              <div class="flex flex-row gap-2">
                <div
                  v-for="fuel in fuelTypes"
                  :key="'fuel types' + fuel.name"
                >
                  <PRadio
                    v-if="fuel.truckTypes.length"
                    :value="selectedFuelType?.enum === fuel.enum"
                    :label="fuel.name"
                    :title="fuel.desc"
                    @input="selectedFuelType = fuel"
                  />
                </div>
              </div>
            </div>


            <PSelect
              class="mt-4"
              item-text="name"
              item-value="id"
              :value="order.truckTypeId"
              :disabled="isUpdating || isModified || (order.noTransport && !selectedFuelType) || (!order.noTransport && !truckTypes.length)"
              :items="selectableTruckTypes"
              :label="$tk('Common.Order.CarType')"
              :required="true"
              @input="onTruckTypeInput($event)"
            />

            <PConfirm
              v-if="confirmQtyHeightChangeVisible"
              type="Warning"
              :acceptButtonText="$tk('Common.General.OK')"
              :label="$tk('Common.Order.CarType')"
              :rejectButtonText="$tk('Common.Actions.Cancel')"
              @accept="onQtyHeightChangeConfirm"
              @reject="onQtyHeightChangeReject"
            >
              <span v-html="$tk('OrderDialog.ReselectVolume')"></span>
            </PConfirm>

            <div :class="['mt-2 p-4', { 'opacity-50': isUpdating || isModified }]">
              <div class="flex items-center space-x-4">
                <div class="w-2/3">
                  <PTruckFill
                    :fill="fill"
                    :truckTypeId="order.truckTypeId"
                  />
                </div>
                <div :class="['w-1/3 flex flex-col items-center justify-center', { 'text-red-500': fill > 1 }]">
                  <div
                    class="text-xs"
                    v-html="$tk('OrderDialog.FillDegree')"
                  ></div>

                  <div class="text-2xl font-bold leading-none whitespace-no-wrap">
                    {{ fillText }}
                  </div>

                  <div
                    v-if="fill > 1"
                    class="text-xs font-medium"
                    v-html="$tk('OrderDialog.ReduceQuantity')"
                  ></div>
                </div>
              </div>
            </div>

            <POrderFees
              v-if="order.fees && fill <= 1"
              :class="['mt-8 hidden md:block', { 'opacity-50': isUpdating || isModified }]"
              :fees="order.fees"
            />
          </template>
        </div>

        <div class="flex-1 p-4 md:overflow-y-auto">
          <div class="flex">
            <div
              class="mb-1 text-sm"
              v-html="$tk('Common.General.RTI')"
            ></div>
            <div class="ml-1 text-red-500">*</div>
          </div>

          <PProductQuantitiesPicker
            v-model="productQuantities"
            :disabled="isUpdating || isModified"
            :locationTo="locationTo"
            :noTransport="order.noTransport"
            :orderId="orderId"
            :showBalances="!isGL"
            :showFill="isHE || isTO"
            :transactionType="transactionType"
            :truckTypeId="order.truckTypeId"
          >
            <template v-slot:blocked="{ product }">
              <div class="text-sm text-center">
                <PTranslation k="OrderDialog.NotAvailable" />
                <br />
                <button
                  class="hidden underline"
                  @click="onNotAvailableContactSupport(product)"
                >
                  <PTranslation k="OrderDialog.ContactSupport" />
                </button>
              </div>
            </template>
          </PProductQuantitiesPicker>

          <template v-if="isGL && !order.locationIdTo">
            <div
              class="text-sm italic"
              v-html="$tk('OrderDialog.PleaseSelectRecipientFirst')"
            ></div>
          </template>
        </div>
      </div>
    </div>

    <div class="border-t border-gray-300 md:hidden">
      <POrderFees
        v-if="order.fees && fill <= 1"
        :class="{ 'opacity-50': isUpdating || isModified }"
        :fees="order.fees"
      />
    </div>

    <PDialogActions>
      <template v-slot:error>
        <p
          v-if="orderError"
          class="ml-auto text-right text-red-700"
        >{{ orderError }}</p>
      </template>
      <div class="flex items-center justify-between w-full pt-2">
        <div class="flex items-bottom space-x-2 gap-2">
          <PButton
            v-if="canDelete"
            color="secondary"
            icon="trash-alt"
            :disabled="this.isModified"
            @click="onDelete"
          >
            <span v-html="deleteOrderText"></span>
          </PButton>

          <PButton
            v-if="canClearTruck"
            color="secondary"
            :disabled="isModified"
            @click="clearTruck"
          >
            <span v-html="$tk('OrderDialog.EmptyCar')"></span>
          </PButton>

          <PButton
            v-if="canCreateSupport"
            color="secondary"
            icon="life-ring"
            :disabled="isModified"
            @click="onCreateSupport"
          >
            <span v-html="$tk('Common.General.Support')"></span>
          </PButton>
        </div>
        <div
          v-if="dateError"
          class="w-full px-2 m-auto text-right text-red-700"
        >
          {{ dateError }}
        </div>
        <div class="flex items-center space-x-2">
          <PButton
            v-if="!isSchedule"
            color="secondary"
            @click="onCancel"
          >
            <span v-html="$tk('Common.Actions.Close')"></span>
          </PButton>

          <!-- isGL -->
          <div class="items-center hidden space-x-2 md:block">
            <PButton
              v-if="saveDraftVisible"
              color="secondary"
              :disabled="!canSubmit"
              @click="onSaveDraft(true)"
            >
              <span>{{ $tk("Common.Actions.SaveDraftAndCreateNew") }}</span>
            </PButton>

            <PButton
              v-if="saveDraftVisible"
              :color="draftOnly ? 'primary' : 'secondary'"
              :disabled="!canSubmit"
              @click="onSaveDraft"
            >
              <span>{{ $tk("Common.Actions.SaveDraft") }}</span>
            </PButton>
          </div>

          <PNumber
            v-if="isTO && !orderId"
            v-model="copies"
            v-tooltip="{ content: $t('OrderDialog.NumberOfCopies') }"
            class="w-16"
            :disabled="!canSubmit || disableCopies"
            :max="9"
            :min="1"
          />

          <PButton
            v-if="!draftOnly"
            color="primary"
            :disabled="!canSubmit"
            @click="onSubmit"
          >
            <span v-html="submitLabel"></span>
          </PButton>
        </div>
      </div>
    </PDialogActions>
  </PDialog>
</template>

<script>
import POrderFees from "@/components/POrderFees";
import PProductQuantitiesPicker from "@/components/forms/PProductQuantitiesPicker";
import POrderModified from "@/components/POrderModified";
import http from "@/http";
import { mapGetters } from "vuex";
import { every, filter, forEach, find, get, map, sumBy, some, pick } from "lodash";

const getAddressKey = (address) => `${address.name}|${address.street}|${address.postalCode}|${address.postalName}`

export default {
  components: {
    POrderFees,
    PProductQuantitiesPicker,
    POrderModified,
  },
  props: {
    orderId: { type: String, required: false },
    transactionType: { type: String, required: true },
    method: {
      type: String,
      default: "order",
      validator: (value) => ["order", "scheduleorder"].indexOf(value) !== -1,
    },
  },
  data() {
    return {
      order: {
        // common
        id: "",
        modified: "",
        modifiedBy: null,
        noTransport: false,
        sendersRef: "",
        receiversRef: "",
        truckTypeId: null,
        transactionType: "",

        // order
        dateSend: null,
        fees: [],
        transportText: "",
        locationIdFrom: "",
        locationIdTo: "",
        shipmentCarNumber: "",
        shipmentRef: "",
        transactions: [],
        isActive: false,

        // schedule
        locationId: "",
        createMonday: false,
        createTuesday: false,
        createWednesday: false,
        createThursday: false,
        createFriday: false,
        createNumWeeks: 1,
        dateBegins: null,
        dateEnds: null,
        schedules: [],
      },

      addresses: [],
      checkIfModifiedTimer: null,
      isInitializing: false, // hide dialog contents and show loader
      isLoadingProducts: false, // hide products and show loader
      isUpdating: false, // make inputs and buttons readonly
      isModified: false,
      minDate: "",
      earliestDate: new Date(),
      defaultEearliestDate: "",
      products: [],
      selectedAddressKey: "",
      fill: 0,
      fillText: "",
      locationTo: null,
      commentId: "",
      commentContent: "",
      confirmQtyHeightChangeVisible: false,
      confirmTruckTypeValue: "",
      rejectTruckTypeValue: "",
      entityModifiedError: false,
      copies: 1,
      disableCopies: false,
      fuelTypes: [],
      selectedFuelType: undefined,
    };
  },
  computed: {
    ...mapGetters(["location", "truckTypes", "defaultTruckTypeId", "user"]),
    assetsBaseUrl() {
      return this.$appConfig.assetsBaseUrl;
    },
    linesProperty() {
      return this.isOrder ? "transactions" : "schedules";
    },
    isOrder() {
      return this.method === "order";
    },
    isSchedule() {
      return this.method === "scheduleorder";
    },
    isTO() {
      return this.transactionType === "TO";
    },
    isHE() {
      return this.transactionType === "HE";
    },
    isGL() {
      return this.transactionType === "GL";
    },
    isDraft() {
      return get(this.order, "transactionStatusId", 0) === 0;
    },
    isCommission() {
      return get(this.location, "isCommission", false);
    },
    isPooling() {
      return get(this.location, "isPooling", false);
    },
    isSmartRetur() {
      return get(this.location, "isSmartRetur", false);
    },
    dialogTitle() {
      const type =
        (this.isPooling || this.isSmartRetur || this.isCommission) &&
          this.transactionType === "TO"
          ? this.$tk("Common.General.Order", true)
          : this.transactionType === "TO"
            ? this.$tk("Common.General.Purchase", true)
            : (this.isPooling || this.isSmartRetur || this.isCommission) &&
              this.transactionType === "HE"
              ? this.$tk("Common.General.Pickup", true)
              : this.transactionType === "HE"
                ? this.$tk("Common.General.Sale", true)
                : this.transactionType === "GL"
                  ? this.$tk("Common.General.Delivery", true)
                  : this.transactionType === "OO"
                    ? this.$tk("Common.General.Delivery", true)
                    : "";

      return this.isOrder && this.orderId
        ? `${type} ${this.orderId}`
        : this.isOrder && this.isTO
          ? this.isPooling || this.isCommission
            ? this.$tk("OrderDialog.NewOrder")
            : this.$tk("OrderDialog.NewPurchase")
          : this.isOrder && this.isHE
            ? this.isPooling || this.isCommission
              ? this.$tk("OrderDialog.NewPickup")
              : this.$tk("OrderDialog.NewSale")
            : this.isOrder && this.isGL
              ? this.$tk("OrderDialog.NewDelivery")
              : this.isSchedule && this.isTO
                ? this.$tk("OrderDialog.ScheduledOrder")
                : this.isSchedule && this.isHE
                  ? this.$tk("OrderDialog.ScheduledPickup")
                  : "";
    },
    dateLabel() {
      return this.isOrder && this.isTO
        ? this.$tk("Common.Order.ReceivalDate")
        : this.isOrder && this.isHE
          ? this.$tk("Common.Order.PickupDate")
          : this.isOrder && this.isGL
            ? this.$tk("Common.Order.Sent")
            : this.isSchedule
              ? this.$tk("Common.Order.StartDate")
              : "";
    },
    dateEndsLabel() {
      return this.$tk("Common.Order.EndDate");
    },
    dateError() { // !No date error for scheduled orders
      return !this.isSchedule &&
        this.earliestDate > new Date(this.order.dateSend) &&
        this.earliestDate.toDateString() !==
        new Date(this.order.dateSend).toDateString()
        ? "Valgte produkter kan ikke leveres til angitt dato"
        : "";
    },
    noTransportLabel() {
      return this.isTO
        ? this.$tk("OrderDialog.SelfPickup")
        : this.isHE
          ? this.$tk("OrderDialog.SelfDelivery")
          : "";
    },
    selectableTruckTypes() {
      if (!this.order.noTransport) return this.truckTypes.filter(truckType => truckType.isStandard)
      if (!this.selectedFuelType) return []
      return this.selectedFuelType.truckTypes.filter(truckType => !truckType.isInternal)
    },
    deleteOrderText() {
      return this.isOrder && this.isTO
        ? this.isPooling || this.isCommission
          ? this.$tk("OrderDialog.DeleteOrder")
          : this.$tk("OrderDialog.DeletePurchase")
        : this.isOrder && this.isHE
          ? this.isPooling || this.isCommission
            ? this.$tk("OrderDialog.DeletePickup")
            : this.$tk("OrderDialog.DeleteSale")
          : this.isOrder && this.isGL
            ? this.$tk("OrderDialog.DeleteDelivery")
            : this.isSchedule && this.isTO
              ? this.$tk("OrderDialog.DeleteScheduledOrder")
              : this.isSchedule && this.isHE
                ? this.$tk("OrderDialog.DeleteScheduledPickup")
                : this.$tk("Common.Actions.Delete");
    },
    submitLabel() {
      return this.isGL
        ? this.$tk("OrderDialog.SendDelivery")
        : this.isTO && !(this.isPooling || this.isCommission)
          ? this.$tk("Common.Actions.Save")
          : this.isTO && (this.isPooling || this.isCommission)
            ? this.$tk("OrderDialog.SendOrder")
            : this.isHE && !(this.isPooling || this.isCommission)
              ? this.$tk("OrderDialog.SendSale")
              : this.isHE && (this.isPooling || this.isCommission)
                ? this.$tk("OrderDialog.SendPickup")
                : this.$tk("Common.Actions.Send");
    },
    saveDraftVisible() {
      return this.isGL && this.isDraft;
    },
    draftOnly() {
      return this.isGL && this.user.isDefaultDraft;
    },
    canSubmit() {
      const okScheduled =
        !this.isSchedule ||
        (this.isSchedule &&
          (this.order.createMonday ||
            this.order.createTuesday ||
            this.order.createWednesday ||
            this.order.createThursday ||
            this.order.createFriday));

      const okOrder =
        this.isUpdating === false &&
        this.isLoadingProducts === false &&
        (((this.isTO || this.isHE) &&
          this.selectedAddressKey !== "" &&
          this.fill <= 1 &&
          this.fill > 0 &&
          this.isModified === false &&
          ((this.order.noTransport && this.selectedFuelType) || !this.order.noTransport)) ||
          (this.isGL &&
            this.order[this.linesProperty].length > 0 &&
            this.order.receiversRef !== "" &&
            this.order.locationIdTo));

      return okScheduled && okOrder && this.copies > 0 && this.dateError === "";
    },
    canDelete() {
      return (
        (this.isOrder?.id &&
          some([0, 1], (id) => id === this.order.transactionStatusId)) ||
        (this.isSchedule && this.order.id)
      );
    },
    canClearTruck() {
      return (this.isTO || this.isHE) && this.quantityOrderedTotal > 0;
    },
    canCreateSupport() {
      return this.isOrder && this.order.id;
    },
    quantityOrderedTotal() {
      return sumBy(this.order[this.linesProperty], "quantityOrdered");
    },
    shouldListProducts() {
      return this.isTO || this.isHE || (this.isGL && this.locationIdTo !== "");
    },
    // schedule
    weekdays() {
      return {
        monday: this.order.createMonday,
        tuesday: this.order.createTuesday,
        wednesday: this.order.createWednesday,
        thursday: this.order.createThursday,
        friday: this.order.createFriday,
      };
    },
    locationIdTo() {
      return this.order.locationIdTo;
    },
    productQuantities: {
      get() {
        return map(this.order[this.linesProperty], (t) => {
          return {
            productId: t.productId,
            qualityId: t.qualityId,
            treatmentId: t.treatmentId,
            quantity: t.quantityOrdered,
            quantityExtra: t.quantityExtra,
          };
        });
      },
      async set(value) {
        // create or update
        forEach(value, (product) => {
          if (product.noNegCommission) console.debug("This product is no neg comm... disable copies??")

          const transaction = find(
            this.order[this.linesProperty],
            (t) =>
              t.productId === product.productId &&
              t.qualityId === product.qualityId &&
              t.treatmentId === product.treatmentId
          );

          if (!transaction && product.quantity !== 0) {
            this.order[this.linesProperty].push({
              productId: product.productId,
              qualityId: product.qualityId,
              treatmentId: product.treatmentId,
              quantityOrdered: product.quantity,
              quantityExtra: product.quantityExtra,
              noNegComm: product.noNegComm,
            });
          } else if (transaction) {
            transaction.quantityOrdered = product.quantity;
            transaction.quantityExtra = product.quantityExtra;
            transaction.noNegComm = product.noNegComm;
          }
        });

        // *** Disable copies if any selected products have limited order quantity
        this.disableCopies = !every(value, (product) => product.quantity > 0 ? !product.noNegComm : true)

        // remove (f.ex if a new destination with less products available is chosen)
        this.order[this.linesProperty] = filter(
          this.order[this.linesProperty],
          (l) =>
            some(
              value,
              (v) =>
                l.productId === v.productId &&
                l.qualityId === v.qualityId &&
                l.treatmentId === v.treatmentId &&
                l.quantityOrdered > 0
            )
        );

        if (this.isTO || this.isHE) {
          await this.updateOrder();
          await this.updateFill();
        }
      },
    },
    orderError() {
      return this.order.noTransport && (!this.order.truckTypeId || !this.selectedFuelType) && (this.isTO || this.isHE)
        ? this.$tk('OrderDialog.FuelAndTruckTypeError') // Velg drivstoff og biltype før du går videre'
        : ''
    }
  },
  watch: {
    disableCopies() {
      if (this.disableCopies) this.copies = 1
    },
    selectedFuelType(newVal, oldVal) {
      if (!newVal && oldVal) {
        const samePickupTruck = this.truckTypes.find((t) => t.id === this.order.truckTypeId)
        if (samePickupTruck) return

        const oldQtyHeight = find(
          oldVal.truckTypes,
          (t) => t.id === this.order.truckTypeId
        )?.qtyHeight;

        const newTruckSameQtyHeight = this.truckTypes.find(t => t.qtyHeight === oldQtyHeight)
        if (newTruckSameQtyHeight) this.order.truckTypeId = newTruckSameQtyHeight.id

      } else {
        const selectedSame = this.selectedFuelType.truckTypes.find((t) => t.id === this.order.truckTypeId)
        if (selectedSame) return

        const oldQtyHeight = find(
          oldVal ? oldVal.truckTypes : this.truckTypes,
          (t) => t.id === this.order.truckTypeId
        )?.qtyHeight;

        const newTruckSameQtyHeight = newVal.truckTypes.find(t => t.qtyHeight === oldQtyHeight)
        if (newTruckSameQtyHeight) this.order.truckTypeId = newTruckSameQtyHeight.id
      }
    }
  },
  methods: {
    numberFormat(number, decimals) {
      return new Intl.NumberFormat(this.$i18n.locale.replace('-sw', ''), {
        minimumFractionDigits: decimals,
      }).format(number);
    },
    async getPostData(withFees) {
      // data object with common
      let data = {
        id: this.order.id,
        transactionType: this.order.transactionType,
      };

      if (this.isTO || this.isHE) {
        data.truckTypeId = this.order.truckTypeId;

        const address = find(
          this.addresses,
          (address) => address.key === this.selectedAddressKey
        );

        if (address) {
          data.addressName = address.name;
          data.addressStreet = address.street;
          data.addressPostalCode = address.postalCode;
          data.addressPostalName = address.postalName;
          data.addressCountryId = address.countryId;
        }
      }

      if (this.isGL && this.order.locationIdTo) {
        const locationTo = await http.get("Location", {
          params: { id: this.order.locationIdTo },
        });
        if (locationTo && locationTo.customerId === this.location.customerId) {
          data.transactionType = "OO";
        }
      }

      if (this.isOrder) {
        data.withFees = withFees;
        data.commit = true;
        data.withSort = false;
        data.withTempSort = false;
        data.withTempSortLines = false;
        data.withTempSortLinesPivoted = false;
        data.withQualityChanges = false;
        data.withEmployees = false;

        data = {
          ...data,
          ...pick(this.order, [
            "modified",
            "dateSend",
            "locationIdFrom",
            "locationIdTo",
            "loadText",
            "transportText",
            "sendersRef",
            "receiversRef",
            "transportRef",
            "shipmentRef",
            "shipmentCarNumber",
            "noTransport",
            "addressName",
            "addressStreet",
            "addressPostalCode",
            "addressPostalName",
          ]),
        };

        data.transactions = map(this.order.transactions, (t) => {
          return pick(t, [
            "id",
            "productId",
            "qualityId",
            "treatmentId",
            "quantityOrdered",
            "quantityExtra",
          ]);
        });
      } else if (this.isSchedule) {
        data = {
          ...data,
          ...pick(this.order, [
            "locationId",
            "transportText",
            "createMonday",
            "createTuesday",
            "createWednesday",
            "createThursday",
            "createFriday",
            "createNumWeeks",
            "dateBegins",
            "dateEnds",
            "reference",
            "noTransport",
          ]),
        };

        data.items = map(this.order.schedules, (s) => {
          return pick(s, [
            "id",
            "productId",
            "qualityId",
            "treatmentId",
            "quantityOrdered",
          ]);
        });
      }

      return data;
    },
    getGetParams(params) {
      if (this.isOrder) {
        params.withSort = false;
        params.withTempSort = false;
        params.withTempSortLines = false;
        params.withTempSortLinesPivoted = false;
        params.withQualityChanges = false;
        params.withEmployees = false;
        params.withFees = true;
      }
      return params;
    },
    async onSaveDraft(createNew = false) {
      this.isUpdating = true;

      try {
        this.order = await http.post(
          this.method,
          await this.getPostData(false)
        );

        await this.crudComment();
      } catch (error) {
        this.handleError(error);
      }

      this.isUpdating = false;

      this.$emit("close", createNew);
    },
    async onSubmit() {
      if (this.isSchedule && !this.order.isActive) await this.updateOrder()
      else {

        this.isUpdating = true;
        let hasErrors = false;

        for (let i = 0; i < this.copies; i++) {
          try {
            // we're assingning the result to this.order
            // so that modified is correct if orderstatus fails
            this.order = await http.post(
              this.method,
              await this.getPostData(false)
            );

            if (this.isOrder) {
              await this.crudComment();

              // GL should go directly to sent (4), otherwise ordered (1)
              await http.post("OrderStatus", {
                id: this.order.id,
                transactionStatus: this.isGL ? 4 : 1,
              });
            } else if (this.isSchedule) {
              await http.post("ScheduleOrderStatus", {
                id: this.order.id,
                isdraft: false,
              });
            }
          } catch (error) {
            hasErrors = true;
            this.handleError(error);
            break;
          }

          // reset order id and transaction ids
          if (this.copies > 1) {
            this.order.id = "";
            forEach(this.order.transactions, (transaction) => {
              transaction.id = "";
            });
          }
        }

        this.isUpdating = false;

        if (!hasErrors) {
          this.$emit("close");
        }
      }
      this.$emit("close")
    },
    async crudComment() {
      // delete comment if existing and text is blank
      if (this.commentId && this.commentContent === "") {
        await http.delete(`OrderComment?id=${this.commentId}`);
        this.commentId = "";

        // create or update if text is not blank
      } else if (this.commentContent !== "") {
        const data = {
          orderId: this.order.id,
          content: this.commentContent,
        };
        if (this.commentId) {
          data.id = this.commentId;
        }

        // post to ordercomment returns comment id
        this.commentId = await http.post("OrderComment", data);
      }
    },
    async onDelete(keepOpen) {
      this.isUpdating = true;
      try {
        await http.delete(`${this.method}?id=${this.order.id}`);
        if (!keepOpen) this.$emit("close");
        else {
          this.order[this.linesProperty] = [];
          this.order.fees = [];
          this.order.id = '';
        }
      } catch (error) {
        this.handleError(error);

        this.order = await http.get(this.method, {
          params: this.getGetParams({ id: this.orderId || this.order.id }),
        });

      }
      this.isUpdating = false;
    },
    async clearTruck() {
      const res = await this.onDelete(true)
      console.debug("on delete truck response:", res)
      this.fill = 0;
      this.fillText = "";
      this.minDate = this.defaultEearliestDate;
      this.earliestDate = new Date(this.defaultEearliestDate);
    },
    async onTruckTypeInput(id) {
      const oldQtyHeight = find(
        this.order.noTransport ? this.selectedFuelType.truckTypes : this.truckTypes,
        (t) => t.id === this.order.truckTypeId
      )?.qtyHeight;
      const newQtyHeight = find(
        this.order.noTransport ? this.selectedFuelType.truckTypes : this.truckTypes,
        (t) => t.id === id
      ).qtyHeight;

      if (
        this.order[this.linesProperty].length > 0 &&
        oldQtyHeight !== newQtyHeight
      ) {
        this.confirmTruckTypeValue = id;
        this.rejectTruckTypeValue = this.order.truckTypeId;
        this.confirmQtyHeightChangeVisible = true;
      } else {
        await this.changeTruckType(id);
      }
    },
    async onQtyHeightChangeConfirm() {
      // clear transactions
      this.clearTruck();

      // change trucktype
      await this.changeTruckType(this.confirmTruckTypeValue);

      // close confirm and reset temp id
      this.confirmQtyHeightChangeVisible = false;
      this.confirmTruckTypeValue = "";
      this.rejectTruckTypeValue = "";
    },
    onQtyHeightChangeReject() {
      // need to reset to make the select go back to original value
      // must set it to blank first to make ui react to change back to original
      this.order.truckTypeId = this.rejectTruckTypeValue;
      this.confirmTruckTypeValue = "";
      this.rejectTruckTypeValue = "";
      this.confirmQtyHeightChangeVisible = false;
    },
    async changeTruckType(id) {
      this.order.truckTypeId = id;

      if (this.isTO || this.isHE) {
        await this.updateFill();
      }
    },
    onModified() {
      this.isModified = true;
    },
    onCreateSupport() {
      this.$emit("support", this.order.id);
    },
    async onNoTransportToggle(value) {
      this.order.noTransport = value;
      if (value) await this.fetchFuelTypes()
      else this.selectedFuelType = undefined
    },
    async fetchFuelTypes() {
      this.fuelTypes = (await http.get("fuelTypes", {
        params: { withTruckTypes: true },
      }))
      if (this.fuelTypes.length)
        this.fuelTypes.map(ft => {
          ft.truckTypes.map((truck) => {
            truck.name = `${truck.name} (${truck.co2KgPerKm} CO<sub>2</sub>kg/km)`
          })
        })
    },
    onCancel() {
      if (this.isSchedule && this.orderId && !this.order[this.linesProperty]?.length) {
        const confirmed = confirm("Bestillingen kan ikke lagres uten noen produkter. Vil du slette bestillingen?")
        if (confirmed) this.$emit("close");
      }
      else this.$emit("close");
    },
    async updateOrder() {
      this.isUpdating = true;

      try {
        // Update order and ask to get fees in the response
        // if (!this.order?.id) return console.error('ingen ordre id funnet')
        if (!this.order[this.linesProperty].length) return this.onDelete(true)

        const order = await http.post(
          this.method,
          await this.getPostData(true)
        );

        // quantityExtra lines will be deleted in backend
        if (!order || !order.id) await this.init();
        else this.order = order;

        // TODO: remove when API supports "yyyy-MM-ddTHH:mm:ss" and not just "yyyy-MM-dd"
        if (
          this.isSchedule &&
          (get(this.order, "dateBegins", "") || "").indexOf("T") !== -1
        ) this.order.dateBegins = this.order.dateBegins.substr(0, "yyyy-MM-dd".length);

        if (
          this.isSchedule &&
          (get(this.order, "dateEnds", "") || "").indexOf("T") !== -1
        ) this.order.dateEnds = this.order.dateEnds.substr(0, "yyyy-MM-dd".length);

        if (this.order.id && this.isOrder && !this.isSchedule) await this.updateEarliestDeliveryDate();

      } catch (error) {
        this.handleError(error);
        this.order = await http.get(this.method, {
          params: this.getGetParams({ id: this.orderId || this.order.id }),
        });
      }

      this.isUpdating = false;
      return
    },
    async updateFill() {
      this.isUpdating = true;

      const lines = [];

      forEach(this.order[this.linesProperty], (item) => {
        const quantityExtra = item.quantityExtra || 0;
        const quantityOrdered = item.quantityOrdered - quantityExtra;
        lines.push(`${item.productId}:${item.qualityId}:${quantityOrdered}`);
      });

      if (lines.length > 0) {
        try {
          const fill = await http.get("OrderFillAll", {
            params: {
              transactionType: this.transactionType,
              truckTypeId: this.order.truckTypeId,
              lines: lines.join(","),
              internal: true,
              rounded: true,
            },
          });
          this.fill = fill.fill;
          this.fillText = fill.fillText;
        } catch (error) {
          this.$store.dispatch("notify", {
            type: "negative",
            text: error.reason,
          });
        }
      } else {
        this.fill = 0;
        this.fillText = "";
      }

      this.isUpdating = false;
    },
    async updateEarliestDeliveryDate() {
      const earliestDate = await http.get("OrderDefaultDate", {
        params: {
          orderId: this.order.id,
        },
      });
      this.minDate = earliestDate.substr(0, "yyyy-MM-dd".length);
      this.earliestDate = new Date(earliestDate);
    },
    async handleError(error) {
      if (error.type === "EntityModified") {
        this.entityModifiedError = true;
      } else {
        this.$store.dispatch("notify", {
          type: "negative",
          text: error.reason,
        });
      }
    },
    setWeekdays(weekdays) {
      this.order.createMonday = weekdays.monday;
      this.order.createTuesday = weekdays.tuesday;
      this.order.createWednesday = weekdays.wednesday;
      this.order.createThursday = weekdays.thursday;
      this.order.createFriday = weekdays.friday;
    },
    async init(orderId) {
      // reset
      this.isInitializing = true;
      this.isModified = false;
      this.entityModifiedError = false;
      this.fill = 0;
      this.fillText = "";
      this.order.id = "";
      this.order.transactions = [];
      this.order.fees = [];
      this.order.noTransport = this.location.transporterId === "DIREKTE";
      if (this.order.noTransport) this.fetchFuelTypes()

      // configure new order
      if (!orderId) {
        this.order.transactionType = this.transactionType;
        if (this.isOrder) {
          switch (this.transactionType) {
            case "TO":
              this.order.locationIdTo = this.location.id;
              this.order.loadText = this.location.loadText;
              this.order.transportText = this.location.transportText;
              break;
            case "HE":
              this.order.locationIdFrom = this.location.id;
              this.order.loadText = this.location.loadText;
              this.order.transportText = this.location.transportText;
              break;
            case "GL":
              this.order.locationIdFrom = this.location.id;
              break;
          }
        } else if (this.isSchedule) {
          this.order.isActive = false
          this.order.locationId = this.location.id;
          switch (this.transactionType) {
            case "TO":
              this.order.transportText = this.location.transportText;
              break;
            case "HE":
              this.order.transportText = this.location.transportText;
              break;
          }
        }
      }

      if (this.isTO || this.isHE) {
        const addresses = await http.get("Addresses", {
          params: {
            valid: true,
            inactive: false,
            addressTypes: "2,3",
            locationId: this.location.id,
          },
        });
        this.addresses = map(addresses, (address) => {
          return {
            ...address,
            key: getAddressKey(address),
          };
        });
      }

      const defaultDate = await http.get("OrderDefaultDate", {
        params: {
          transactionType: this.transactionType,
          locationId: this.location.id,
        },
      });
      this.minDate = defaultDate.substr(0, "yyyy-MM-dd".length);
      this.defaultEearliestDate = defaultDate.substr(0, "yyyy-MM-dd".length);
      this.earliestDate = new Date(defaultDate);

      // get order if we're opening an existing order
      // this will populate inputs with v-model="order.<prop>"
      // except address that needs to be set manually as
      // we post actual addressXXX fields, and retrieves addressTo.XXX

      if (orderId) {
        this.order = await http.get(this.method, {
          params: this.getGetParams({ id: orderId }),
        });

        const addressField = this.isHE ? "addressFrom" : "addressTo";
        this.selectedAddressKey = getAddressKey(
          get(this.order, addressField, "")
        );

        // TODO: remove when API supports "yyyy-MM-ddTHH:mm:ss" and not just "yyyy-MM-dd"
        if (
          this.isSchedule &&
          (get(this.order, "dateBegins", "") || "").indexOf("T") !== -1
        ) {
          this.order.dateBegins = this.order.dateBegins.substr(
            0,
            "yyyy-MM-dd".length
          );
        }

        if (
          this.isSchedule &&
          (get(this.order, "dateEnds", "") || "").indexOf("T") !== -1
        ) {
          this.order.dateEnds = this.order.dateEnds.substr(
            0,
            "yyyy-MM-dd".length
          );
        }

        if (this.order.locationIdTo) {
          this.locationTo = await http.get("Location", {
            params: { id: this.order.locationIdTo },
          });
        }

        if (this.isTO || this.isHE) {
          await this.updateFill();
        }

        // when creating a GL it is also possible to create an initial comment
        if (this.isGL) {
          const comments = await http.get("OrderComments", {
            params: {
              orderId: this.orderId,
            },
          });
          if (comments.length > 0 && comments[0] !== null) {
            this.commentId = comments[0].id;
            this.commentContent = comments[0].content;
          }
        }
      } else {
        if (this.isTO || this.isHE) {
          this.order.truckTypeId =
            this.location.truckTypeId || this.defaultTruckTypeId;
        }
      }

      // defaults
      if (this.isOrder && !this.order.dateSend) {
        this.order.dateSend = this.minDate;
      } else if (this.isSchedule && !this.order.dateBegins) {
        this.order.dateBegins = this.minDate;
      }

      // Either no matching address was found on order,
      // or this is a new order. fall back to first address as default.

      if (!this.selectedAddressKey) {
        this.selectedAddressKey =
          this.addresses.length > 0 ? getAddressKey(this.addresses[0]) : "";
      }

      this.isInitializing = false;
    },
    onLocationToSelect(location) {
      this.order.locationIdTo = location.id;
      this.locationTo = location;
    },
    onNotAvailableContactSupport(product) {
      if (confirm(this.$t("OrderDialog.ContactSupportContinue"))) {
        this.$router.push({
          name: "support.Tickets",
          query: {
            c: "ORDDELIVER",
            productId: product.productId,
            qualityId: product.qualityId,
            treatmentId: product.treatmentId,
          },
        });
      }
    },
  },
  async created() {
    await this.init(this.orderId);

    if (this.orderId) { // existing order, set fuel type based on truckTypeId
      await this.fetchFuelTypes()
      this.fuelTypes.forEach(ft => {
        ft.truckTypes.forEach(truck => {
          if (truck.id === this.order.truckTypeId) this.selectedFuelType = ft
        })
      })
    }
  },
};
</script>

<style>
.order-dialog {
  max-width: 90%;
}

@media md {
  .order-dialog-header {
    width: 420px;
  }

  .order-dialog {
    min-width: 940px;
    max-width: 1280px;
  }
}

a[disabled] {
  color: theme("colors.gray.400");
}
</style>