<template>
  <div
    v-if="fees.length > 0"
    class="p-2 bg-white shadow"
  >
    <table
      class="w-full text-xs leading-loose"
    >
      <tr
        v-for="(fee, index) in groupedFees"
        :key="index"
        class="border-b border-gray-200"
      >
        <td class="align-top">{{ fee.feeTypeName }}</td>
        <td class="pl-1 align-top">
          <div v-if="fee.productName">{{ fee.productName }}</div>
          <div v-if="fee.qualityName">{{ fee.qualityName }}</div>
        </td>
        <td class="pl-1 text-right whitespace-no-wrap align-top">{{ fee.amount }}</td>
      </tr>
      <tr class="font-semibold">
        <td colspan="2">Sum</td>
        <td class="pl-2 text-right whitespace-no-wrap">{{ feesTotal }}</td>
      </tr>
    </table>
  </div>
</template>

<script>

import {
  forEach,
  map,
  groupBy,
  sumBy,
  toPairs
} from "lodash"

export default {
  name: "POrderFees",

  props: {
    fees: Array,
    default: () => []
  },

  computed: {

    groupedFees () {

      const feesWithGroupingKey = map(this.fees, fee => {
        fee.groupKey = `${fee.feeTypeName}-${fee.qualityName}`
        return fee
      })

      const pairs = toPairs(groupBy(feesWithGroupingKey, "groupKey"))

      const fees = []

      forEach(pairs, pair => {
        fees.push({
          feeTypeName: pair[1][0].feeTypeName,
          productName: pair[1][0].productName,
          qualityName: pair[1][0].qualityName,
          amount: this.numberFormat(sumBy(pair[1], "amount"))
        })
      })

      return fees
    },

    feesTotal () {
      return this.numberFormat(sumBy(this.fees, "amount"))
    }
  },

  methods: {
    numberFormat (number) {
      return new Intl.NumberFormat(this.$i18n.locale.replace('-sw', ''), {
        minimumFractionDigits: 2
      }).format(number)
    }
  }
}
</script>